import React, { useEffect, useState } from 'react';
import {BrowserRouter, Route, Routes } from 'react-router-dom'; //Navigate
import "./css/Swiper.css"
import "./css/Popup.css"
import "./css/Animations.css"
import "./css/Style.css"
import "./css/Calculator.css"
import "./css/Dashboard.css"
import "./css/SuccessLoanForm.css"
import "./css/BecomeMemberForm.css"
import "./css/TransitionPage.css"

import { ThemeProvider } from "@mui/styles";
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import './font-awesome-4.7.0/css/font-awesome.min.css'
import './font-awesome-4.7.0/css/font-awesome.css'
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import DepositeRate from './pages/DepositeRate';
import Notfound404 from './pages/NotFound404';
import Cart from './pages/Cart';
import ToolData from './data/Tools.json'
import Checkout from './pages/Checkout';
import SearchResult from './pages/SearchResult';
import Success from './pages/Success';


//Check of Style (responsiveness)



const App: React.FC =()=> {


  let theme = createTheme({
    breakpoints: {
      values: {
        xs: 0, // Extra small
        sm: 600, // Small
        md: 1000, // Medium
        lg: 1200, // Large
        xl: 1536, // Extra large
        // Add custom breakpoints here (e.g., xxl: 1800)
      }
    },
  });




  /*
=============================================================== 
//* AOS iNIT
===============================================================
*/
useEffect(()=> {
  AOS.init({
    duration: 400,
    easing: 'ease-in-sine',
    delay: 0,
    once: true,
  })
  AOS.refresh()

  return () => {
    AOS.refresh()
  }
}, [])

const [isLoading, setIsLoading] = useState(true)

useEffect(()=>{
  setIsLoading(false)
})

const [siteData, setSiteData] = useState<any>(ToolData)
const [countCart, setCountCart] = useState(0)

  return (
      <ThemeProvider theme={theme}>
        <div className="App">
      
          <BrowserRouter>
            <Routes>
              
              <Route path='/' element={<DepositeRate isLoading={isLoading} siteData={siteData} setSiteData={setSiteData} countCart={countCart} setCountCart={setCountCart}/>}/>
              <Route path='/cart' element={<Cart siteData={siteData} setSiteData={setSiteData} countCart={countCart} setCountCart={setCountCart} />} />
              <Route path='/search-results/:search' element={<SearchResult  siteData={siteData} setSiteData={setSiteData} countCart={countCart} setCountCart={setCountCart}/>}/>
          
              <Route path='/checkout/:temppaymentid/:paymentid' element={<Checkout />}/>

              <Route path='/payment-success' element={<Success />}/>

              <Route path='/error&oq=error&gs_lcrp=' element={<Notfound404 />}/>
              <Route path='*' element={<Notfound404 />}/>
      
            </Routes>
          </BrowserRouter>

        </div>
      </ThemeProvider>
  );
}

export default App;
