import React, { useEffect, useState } from 'react'
import useStyles from '../styles/search_result';
import { display, styled } from '@mui/system'
import Fuse from "fuse.js"; //using fuse to simplify live search

interface Props {
    searchTerm: string | undefined;    
    siteData: any;
    setSiteData: any;
    countCart: any;
    setCountCart: any;
}
const SeachResultContainer: React.FC<Props> = ({searchTerm, siteData, setSiteData, countCart, setCountCart}) => {
    const classes = useStyles()
    useEffect(() => {
        const sectionEl = document.getElementById("search-result-hero")
        const handleScroll = () => {
            const position = 217.168 + window.scrollY * 0.65
            if(sectionEl){
                if(position >= 500){
                    sectionEl.style.backgroundPosition = `0px -500px`
                }else{
                    sectionEl.style.backgroundPosition = `0px -${position}px`
                }
            }
            
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => window.removeEventListener('scroll', handleScroll);
      }, []);
    const MainContent = styled('section')(({ theme }) => ({
        position: 'relative',
        overflow: 'hidden',
        background: '#fff',
        margin: '0',
    }));


    function combineSixArrays<T>(arr1: T[], arr2: T[], arr3: T[], arr4: T[], arr5: T[], arr6: T[], arr7: T[]): T[] {
        const combinedArray = [...arr1, ...arr2, ...arr3, ...arr4, ...arr5, ...arr6, ...arr7];
        return combinedArray;
    }

    const [searchPhrase, setSerchPhrase] = useState<any>(searchTerm)
    const [searchResult, setSearchResult] = useState<any>([])
    
    const [isSearchTermShort, setIsSearchTermShort] = useState<boolean>(true)

    const searchFunction =()=>{
        const fuse = new Fuse(siteData.fortnite, {keys: ['name', 'price', 'game' ]})
        const results = fuse.search(searchPhrase).map(({ item }) => item)

        const fuseApex = new Fuse(siteData.apex, {keys: ['name', 'price', 'game' ]})
        const resultsApex = fuseApex.search(searchPhrase).map(({ item }) => item)

        const fusePokemon = new Fuse(siteData.pokemon, {keys: ['name', 'price', 'game' ]})
        const resultsPokemon = fusePokemon.search(searchPhrase).map(({ item }) => item)

        const fuseGta = new Fuse(siteData.gta, {keys: ['name', 'price', 'game' ]})
        const resultsGta = fuseGta.search(searchPhrase).map(({ item }) => item)

        const fuseCod = new Fuse(siteData.cod, {keys: ['name', 'price', 'game' ]})
        const resultsCod = fuseCod.search(searchPhrase).map(({ item }) => item)

        const fuseRainbow6 = new Fuse(siteData.rainbow6, {keys: ['name', 'price', 'game' ]})
        const resultsRainbow6 = fuseRainbow6.search(searchPhrase).map(({ item }) => item)

        const fuseMobile = new Fuse(siteData.mobile, {keys: ['name', 'price', 'game' ]})
        const resultsMobile = fuseMobile.search(searchPhrase).map(({ item }) => item)

        

        setSearchResult(combineSixArrays(results, resultsApex, resultsPokemon, resultsGta, resultsCod, resultsRainbow6, resultsMobile))

    }

    useEffect(()=>{
        if(searchPhrase.length < 3){
            setIsSearchTermShort(true)
        }else{
            setIsSearchTermShort(false)
        }
    }, [searchPhrase])
    useEffect(()=>{
        searchFunction()
    }, [searchPhrase, countCart])

    const handleSearchFormSubmit =(e: React.ChangeEvent<HTMLFormElement>)=>{
        e.preventDefault()
        
        searchFunction()
    }

    const addToCart =( game: string, id: any )=> {
        switch (game) {
            case 'fortnite':
                setSiteData({...siteData, fortnite: siteData.fortnite.map( (item: any) => item.id === id ? { ...item, incart: true } : {...item} )})
                setCountCart(countCart + 1)
                break;
            case 'apex':
                setSiteData({...siteData, apex: siteData.apex.map( (item: any) => item.id === id ? { ...item, incart: true } : {...item} )})
                setCountCart(countCart + 1)
                break;
            case 'pokemon':
                setSiteData({...siteData, pokemon: siteData.pokemon.map( (item: any) => item.id === id ? { ...item, incart: true } : {...item} )})
                setCountCart(countCart + 1)
                break;
            case 'gta':
                setSiteData({...siteData, gta: siteData.gta.map( (item: any) => item.id === id ? { ...item, incart: true } : {...item} )})
                setCountCart(countCart + 1)
                break;
            case 'cod':
                setSiteData({...siteData, cod: siteData.cod.map( (item: any) => item.id === id ? { ...item, incart: true } : {...item} )})
                setCountCart(countCart + 1)
                break;
            case 'rainbow6':
                setSiteData({...siteData, rainbow6: siteData.rainbow6.map( (item: any) => item.id === id ? { ...item, incart: true } : {...item} )})
                setCountCart(countCart + 1)
                break;
            case 'mobile':
                setSiteData({...siteData, mobile: siteData.mobile.map( (item: any) => item.id === id ? { ...item, incart: true } : {...item} )})
                setCountCart(countCart + 1)
                break;
                
        
            default:
                break;
        }
    }
  return (
        <main>
            <div className={classes.hero} id='search-result-hero'>
                <div className={classes.heroInner}>
                    <h1 className={classes.heroInnerH1}>
                        <span className={classes.searchResultSpan}>Search</span>

                        <form onSubmit={handleSearchFormSubmit} style={{textAlign: 'right', display: 'flex', alignItems: 'center', justifyContent: 'right'}}>	
                            <input 
                                className={classes.searchInput} 
                                id="tipue_search_input" 
                                aria-label="What do you want to search?"
                                value={searchPhrase}
                                onChange={(e)=> setSerchPhrase(e.currentTarget.value)}
                            />
                            <button className={classes.searchButton} id="tipue_search_button" aria-label="Search">Search</button>
                        </form>
                    </h1>
                </div>
            </div>

            <MainContent>
                <div className={classes.mainContentInner}>
                        <div className={classes.mainContentInnerContainer}>
                            <div style={{clear: 'both', float: 'none', paddingRight: '0', position: 'relative', width: '100%'}}>
                                <div className={classes.pageTitle}>
                                    <h2 className={classes.pageTitleH2}>Search Results</h2>
                                </div>

                                {
                                    isSearchTermShort
                                    ?
                                    <div>
                                        <div id="tipue_search_warning_head" style={{color: '#555', lineHeight: '1.5', fontSize: '0.878rem'}}>Search too short</div>
                                        <div id="tipue_search_warning" style={{color: '#555', lineHeight: '1.5', fontSize: '0.878rem'}}>Should be 3 characters or more</div>
                                    </div>
                                    :
                                    searchResult.length === 0
                                    ?
                                    <div>
                                        <div id="tipue_search_warning_head" style={{color: '#555', lineHeight: '1.5', fontSize: '0.878rem'}}>Nothing found</div>
                                    </div>
                                    :
                                    <>
                                        <div>
                                            <div className={classes.searchResultCount}>{searchResult.length} results</div>
                                        </div>

                                        {
                                            searchResult.map((item: any, index: number) => {
                                                const { id, name, price, incart, game } = item
                                                return (
                                                    <ul key={index} style={{marginBottom: '1.5rem', paddingLeft: '20px'}}>
                                                        <li className="tipue_search_content_text">{name} - ${price} - { !incart ? <a style={{textDecoration: 'underline', color: 'blue', cursor: 'pointer'}} className={classes.rpcarttabletext} onClick={()=> addToCart( game, id )}>add to cart</a> : <a className={classes.rpcarttabletext} style={{color: '#777', cursor: 'not-allowed'}}>in cart</a> }</li>
                                                        <div className="tipue_search_content_loc">{game}</div>
                                                    </ul>
                                                )
                                            })
                                        }
                                    </>

                                }
                                

                                
                            </div>
                        </div>
                </div>


            </MainContent>
        </main>
  )
}

export default SeachResultContainer
