import React, { useEffect, useState } from 'react'

const Success: React.FC= () => {

    const [ currentURL, setCurrentURL] = useState(window.location.pathname)

  useEffect(() => {
    document.title = 'Successful!!!';

  }, []);

  function openInSameTab(url: string) {
    window.location.href = url;
  }

  useEffect(()=>{
    setTimeout(() => {
        openInSameTab(process.env.REACT_APP_SITE_HOME_PAGE ||  '')
    }, 1000);
  }, [])


  return (
    <div style={{padding: '0.5rem'}}>
        <h1 style={{marginBottom: '1rem'}}>Success!!!</h1>
    </div>
  )
}

export default Success