import React, { useEffect, useState } from 'react'
import useStyles from '../styles/deposite_rate';
import { styled } from '@mui/system'
import { get, set, remove, clear } from 'local-storage';
import Popup from '../components/Popup';

interface Props {
    siteData: any;
    setSiteData: any;
    countCart: any;
    setCountCart: any;
}
const DepositeRateContainer: React.FC<Props> = ({ siteData, setSiteData, countCart, setCountCart }) => {
    const classes = useStyles()


    useEffect(() => {
        const sectionEl = document.getElementById("deposite-rate-hero")
        const handleScroll = () => {
            const position = 217.168 + window.scrollY * 0.65
            if(sectionEl){
                if(position >= 500){
                    sectionEl.style.backgroundPosition = `0px -500px`
                }else{
                    sectionEl.style.backgroundPosition = `0px -${position}px`
                }
            }
            
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => window.removeEventListener('scroll', handleScroll);
      }, []);
    const Hero = styled('section')(({ theme }) => ({
        position: 'relative',
        backgroundImage: 'url(/imgs/cu_service.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: '0px -217.168px',
        padding: '140px 0px',
        top: '-100px',
        marginBottom: '-100px',
        textShadow: 'none !important',
        borderBottom: 'none',
        backgroundColor: 'transparent',
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        transition: 'all 0.17s linear !important',

        [theme.breakpoints.down('md')]: {
            padding: '100px 0px',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '60px 0px',
            backgroundPosition: 'center !important'
        },
    }));
    const MainContent = styled('section')(({ theme }) => ({
        position: 'relative',
        overflow: 'hidden',
        background: '#fff',
        margin: '0',
    }));



    const addToCart =( game: string, id: any )=> {
        switch (game) {
            case 'fortnite':
                setSiteData({...siteData, fortnite: siteData.fortnite.map( (item: any) => item.id === id ? { ...item, incart: true } : {...item} )})
                setCountCart(countCart + 1)
                break;
            case 'apex':
                setSiteData({...siteData, apex: siteData.apex.map( (item: any) => item.id === id ? { ...item, incart: true } : {...item} )})
                setCountCart(countCart + 1)
                break;
            case 'pokemon':
                setSiteData({...siteData, pokemon: siteData.pokemon.map( (item: any) => item.id === id ? { ...item, incart: true } : {...item} )})
                setCountCart(countCart + 1)
                break;
            case 'gta':
                setSiteData({...siteData, gta: siteData.gta.map( (item: any) => item.id === id ? { ...item, incart: true } : {...item} )})
                setCountCart(countCart + 1)
                break;
            case 'cod':
                setSiteData({...siteData, cod: siteData.cod.map( (item: any) => item.id === id ? { ...item, incart: true } : {...item} )})
                setCountCart(countCart + 1)
                break;
            case 'rainbow6':
                setSiteData({...siteData, rainbow6: siteData.rainbow6.map( (item: any) => item.id === id ? { ...item, incart: true } : {...item} )})
                setCountCart(countCart + 1)
                break;
            case 'mobile':
                setSiteData({...siteData, mobile: siteData.mobile.map( (item: any) => item.id === id ? { ...item, incart: true } : {...item} )})
                setCountCart(countCart + 1)
                break;            
            default:
                break;
        }
    }


    const [needed, setNeeded] = useState(false)
    const [game, setGame] = useState('')
    const [toolId, setToolId] = useState(0)
    const [disc, setDisc] = useState<any>([])
    const [incart, setIncart] = useState<boolean>()

    const handleOpenDisc =( description: any, id: number, game: string, incart: boolean  )=>{
        if(description){
            setToolId(id)
            setGame(game)
            setNeeded(true)
            setDisc(description)
            setIncart(incart)
        }
    }

  return (
        <main>
            <Hero id='deposite-rate-hero'>
                <div className={classes.heroInner}>
                    <h1 className={classes.heroInnerH1}>Fixing and Gaming Services</h1>
                    <p className={classes.heroInnerP}>Fixing and repairing all kinds of gaming accounts and unlocking devices/gadgets with software developed by our qualified software developers.</p>
                </div>
            </Hero>

            <MainContent>
                <div className={classes.mainContentInner}>
                        <div className={classes.mainContentInnerContainer}>
                            <div style={{clear: 'both', float: 'none', paddingRight: '0', position: 'relative', width: '100%'}}>
                                
                                {
                                    siteData.fortnite.length === 0
                                    ?
                                    null
                                    :
                                    <div style={{margin: '50px 0'}}>
                                        <table className="ratetable twoCol" id="new-cars">
                                            <caption className={classes.caption}>
                                                <div className="inputdiv">FORTNITE Account Software</div>
                                            </caption>
                                            <tbody> 
                                                <tr> 
                                                    <th className={classes.th}>Software Type</th>
                                                    <th className={classes.th} style={{textAlign: 'center'}}>Price</th>
                                                    <th className={classes.th} style={{textAlign: 'center'}}></th>
                                                </tr>
                                                
                                                
                                                {
                                                    siteData
                                                    ?
                                                    siteData.fortnite.map((item: any) =>{
                                                        return (
                                                            <tr key={item.id}> 
                                                                <td onClick={()=> handleOpenDisc( item.discription, item.id, item.game, item.incart)} style={{ cursor: `${ item.discription ? 'pointer' : null }` }}>{item.name} {item.discription ? <span style={{color: 'blue', textDecoration: 'underline', fontSize: '0.8rem'}}>...see more</span> : null}</td>
                                                                <td>$ {item.price}</td>
                                                                <td> { !item.incart ? <a style={{textDecoration: 'underline', color: 'blue', cursor: 'pointer'}} className={classes.rpcarttabletext} onClick={()=> addToCart( 'fortnite', item.id )}>add to cart</a> : <a className={classes.rpcarttabletext} style={{color: '#777', cursor: 'not-allowed'}}>in cart</a> } </td>
                                                            </tr>
                                                        )
                                                    })
                                                    : 
                                                    null
                                                }
                                                
                                                
                                                {/* <tr> 
                                                    <td colSpan={3} className={classes.tablefoot}>*APY is the Annual Percentage Yield.</td>
                                                </tr> */}
                                            </tbody>
                                        </table>


                                    </div>
                                }
                                
                                {
                                    siteData.apex.length === 0
                                    ?
                                    null
                                    :
                                    <div style={{margin: '50px 0'}}>
                                        <table className="ratetable twoCol" id="new-cars">
                                            <caption className={classes.caption}>
                                                <div className="inputdiv">Apex Account Software</div>
                                            </caption>
                                            <tbody> 
                                                <tr> 
                                                    <th className={classes.th}>Software Type</th>
                                                    <th className={classes.th} style={{textAlign: 'center'}}>Price</th>
                                                    <th className={classes.th} style={{textAlign: 'center'}}></th>
                                                </tr>
                                                
                                                
                                                {
                                                    siteData
                                                    ?
                                                    siteData.apex.map((item: any) =>{
                                                        return (
                                                            <tr key={item.id}> 
                                                                <td onClick={()=> handleOpenDisc( item.discription, item.id, item.game, item.incart)} style={{ cursor: `${ item.discription ? 'pointer' : null }` }}>{item.name} {item.discription ? <span style={{color: 'blue', textDecoration: 'underline', fontSize: '0.8rem'}}>...see more</span> : null}</td>
                                                                <td>$ {item.price}</td>
                                                                <td> { !item.incart ? <a style={{textDecoration: 'underline', color: 'blue', cursor: 'pointer'}} className={classes.rpcarttabletext} onClick={()=> addToCart( 'fortnite', item.id )}>add to cart</a> : <a className={classes.rpcarttabletext} style={{color: '#777', cursor: 'not-allowed'}}>in cart</a> } </td>
                                                            </tr>
                                                        )
                                                    })
                                                    : 
                                                    null
                                                }
                                        
                                            </tbody>
                                        </table>

                                    </div>
                                }

                                {
                                    siteData.pokemon.length === 0
                                    ?
                                    null
                                    :
                                    <div style={{margin: '50px 0'}}>
                                        <table className="ratetable twoCol" id="new-cars">
                                            <caption className={classes.caption}>
                                                <div className="inputdiv">Pokemon Go</div>
                                            </caption>
                                            <tbody> 
                                                <tr> 
                                                    <th className={classes.th}>Software Type</th>
                                                    <th className={classes.th} style={{textAlign: 'center'}}>Price</th>
                                                    <th className={classes.th} style={{textAlign: 'center'}}></th>
                                                </tr>
                                                
                                                
                                                {
                                                    siteData
                                                    ?
                                                    siteData.pokemon.map((item: any) =>{
                                                        return (
                                                            <tr key={item.id}> 
                                                                <td onClick={()=> handleOpenDisc( item.discription, item.id, item.game, item.incart)} style={{ cursor: `${ item.discription ? 'pointer' : null }` }}>{item.name} {item.discription ? <span style={{color: 'blue', textDecoration: 'underline', fontSize: '0.8rem'}}>...see more</span> : null}</td>
                                                                <td>$ {item.price}</td>
                                                                <td> { !item.incart ? <a style={{textDecoration: 'underline', color: 'blue', cursor: 'pointer'}} className={classes.rpcarttabletext} onClick={()=> addToCart( 'fortnite', item.id )}>add to cart</a> : <a className={classes.rpcarttabletext} style={{color: '#777', cursor: 'not-allowed'}}>in cart</a> } </td>
                                                            </tr>
                                                        )
                                                    })
                                                    : 
                                                    null
                                                }
                                        
                                            </tbody>
                                        </table>

                                    </div>
                                }

                                {
                                    siteData.gta.length === 0
                                    ?
                                    null
                                    :
                                    <div style={{margin: '50px 0'}}>
                                        <table className="ratetable twoCol" id="new-cars">
                                            <caption className={classes.caption}>
                                                <div className="inputdiv">Grand Theft Auto Account Software</div>
                                            </caption>
                                            <tbody> 
                                                <tr> 
                                                    <th className={classes.th}>Software Type</th>
                                                    <th className={classes.th} style={{textAlign: 'center'}}>Price</th>
                                                    <th className={classes.th} style={{textAlign: 'center'}}></th>
                                                </tr>
                                                
                                                
                                                {
                                                    siteData
                                                    ?
                                                    siteData.gta.map((item: any) =>{
                                                        return (
                                                            <tr key={item.id}> 
                                                                <td onClick={()=> handleOpenDisc( item.discription, item.id, item.game, item.incart)} style={{ cursor: `${ item.discription ? 'pointer' : null }` }}>{item.name} {item.discription ? <span style={{color: 'blue', textDecoration: 'underline', fontSize: '0.8rem'}}>...see more</span> : null}</td>
                                                                <td>$ {item.price}</td>
                                                                <td> { !item.incart ? <a style={{textDecoration: 'underline', color: 'blue', cursor: 'pointer'}} className={classes.rpcarttabletext} onClick={()=> addToCart( 'fortnite', item.id )}>add to cart</a> : <a className={classes.rpcarttabletext} style={{color: '#777', cursor: 'not-allowed'}}>in cart</a> } </td>
                                                            </tr>
                                                        )
                                                    })
                                                    : 
                                                    null
                                                }
                                        
                                            </tbody>
                                        </table>

                                    </div>
                                }

                                {
                                    siteData.cod.length === 0
                                    ?
                                    null
                                    :
                                    <div style={{margin: '50px 0'}}>
                                        <table className="ratetable twoCol" id="new-cars">
                                            <caption className={classes.caption}>
                                                <div className="inputdiv">Call of Duty Account Software</div>
                                            </caption>
                                            <tbody> 
                                                <tr> 
                                                    <th className={classes.th}>Software Type</th>
                                                    <th className={classes.th} style={{textAlign: 'center'}}>Price</th>
                                                    <th className={classes.th} style={{textAlign: 'center'}}></th>
                                                </tr>
                                                
                                                
                                                {
                                                    siteData
                                                    ?
                                                    siteData.cod.map((item: any) =>{
                                                        return (
                                                            <tr key={item.id}> 
                                                                <td onClick={()=> handleOpenDisc( item.discription, item.id, item.game, item.incart)} style={{ cursor: `${ item.discription ? 'pointer' : null }` }}>{item.name} {item.discription ? <span style={{color: 'blue', textDecoration: 'underline', fontSize: '0.8rem'}}>...see more</span> : null}</td>
                                                                <td>$ {item.price}</td>
                                                                <td> { !item.incart ? <a style={{textDecoration: 'underline', color: 'blue', cursor: 'pointer'}} className={classes.rpcarttabletext} onClick={()=> addToCart( 'fortnite', item.id )}>add to cart</a> : <a className={classes.rpcarttabletext} style={{color: '#777', cursor: 'not-allowed'}}>in cart</a> } </td>
                                                            </tr>
                                                        )
                                                    })
                                                    : 
                                                    null
                                                }
                                        
                                            </tbody>
                                        </table>

                                    </div>
                                }

                                {
                                    siteData.rainbow6.length === 0
                                    ?
                                    null
                                    :
                                    <div style={{margin: '50px 0'}}>
                                        <table className="ratetable twoCol" id="new-cars">
                                            <caption className={classes.caption}>
                                                <div className="inputdiv">Rainbow Six Account Software</div>
                                            </caption>
                                            <tbody> 
                                                <tr> 
                                                    <th className={classes.th}>Software Type</th>
                                                    <th className={classes.th} style={{textAlign: 'center'}}>Price</th>
                                                    <th className={classes.th} style={{textAlign: 'center'}}></th>
                                                </tr>
                                                
                                                
                                                {
                                                    siteData
                                                    ?
                                                    siteData.rainbow6.map((item: any) =>{
                                                        return (
                                                            <tr key={item.id}> 
                                                                <td onClick={()=> handleOpenDisc( item.discription, item.id, item.game, item.incart)} style={{ cursor: `${ item.discription ? 'pointer' : null }` }}>{item.name} {item.discription ? <span style={{color: 'blue', textDecoration: 'underline', fontSize: '0.8rem'}}>...see more</span> : null}</td>
                                                                <td>$ {item.price}</td>
                                                                <td> { !item.incart ? <a style={{textDecoration: 'underline', color: 'blue', cursor: 'pointer'}} className={classes.rpcarttabletext} onClick={()=> addToCart( 'fortnite', item.id )}>add to cart</a> : <a className={classes.rpcarttabletext} style={{color: '#777', cursor: 'not-allowed'}}>in cart</a> } </td>
                                                            </tr>
                                                        )
                                                    })
                                                    : 
                                                    null
                                                }
                                        
                                            </tbody>
                                        </table>

                                    </div>
                                }

                                {
                                    siteData.mobile.length === 0
                                    ?
                                    null
                                    :
                                    <div style={{margin: '50px 0'}}>
                                        <table className="ratetable twoCol" id="new-cars">
                                            <caption className={classes.caption}>
                                                <div className="inputdiv">Mobile Software</div>
                                            </caption>
                                            <tbody> 
                                                <tr> 
                                                    <th className={classes.th}>Software Type</th>
                                                    <th className={classes.th} style={{textAlign: 'center'}}>Price</th>
                                                    <th className={classes.th} style={{textAlign: 'center'}}></th>
                                                </tr>
                                                
                                                
                                                {
                                                    siteData
                                                    ?
                                                    siteData.mobile.map((item: any) =>{
                                                        return (
                                                            <tr key={item.id}> 
                                                                <td onClick={()=> handleOpenDisc( item.discription, item.id, item.game, item.incart)} style={{ cursor: `${ item.discription ? 'pointer' : null }` }}>{item.name} {item.discription ? <span style={{color: 'blue', textDecoration: 'underline', fontSize: '0.8rem'}}>...see more</span> : null}</td>
                                                                <td>$ {item.price}</td>
                                                                <td> { !item.incart ? <a style={{textDecoration: 'underline', color: 'blue', cursor: 'pointer'}} className={classes.rpcarttabletext} onClick={()=> addToCart( 'fortnite', item.id )}>add to cart</a> : <a className={classes.rpcarttabletext} style={{color: '#777', cursor: 'not-allowed'}}>in cart</a> } </td>
                                                            </tr>
                                                        )
                                                    })
                                                    : 
                                                    null
                                                }
                                        
                                            </tbody>
                                        </table>

                                    </div>
                                }


                            </div>
                        </div>
                </div>

            <Popup needed={needed} setNeeded={setNeeded} game={game} setGame={setGame} toolId={toolId} setToolId={setToolId} disc={disc} setDisc={setDisc} addToCart={addToCart} incart={incart} setIncart={setIncart}/>
            </MainContent>

        </main>
  )
}

export default DepositeRateContainer
