import React, { useEffect } from 'react'
import DepositeRateContainer from '../containers/DepositeRate'
import Nav from '../components/nav/Nav';
import { Audio } from 'react-loader-spinner'
import Footer from '../components/nav/Footer';

interface Props {
  isLoading: any;
  siteData: any;
  setSiteData: any;
  countCart: any;
  setCountCart: any;
}
const DepositeRate: React.FC<Props> = ( { isLoading, siteData, setSiteData, countCart, setCountCart } ) => {

  // const navigate = useNavigate()
  // useEffect(()=>{
  //   if(link === 'link1'){
  //     const url = process.env.REACT_APP_VALIDATE_PAYMENTID || ''
  //     axios.post(url, { paymentid: paymentid })
  //     .then(function (response: any) {
  //       setIsLoading(false)
  //       if(response.data.isvalidated === true){
  //         setVerifiedPaymentId(paymentid)
  //         set('verify order', paymentid)
  //         setIsvalidate(get('verify order'))
  //       }else{
  //         remove('verify order')
  //         setIsvalidate(get('verify order'))
  //         navigate('/error&oq=error&gs_lcrp=')
  //       }
  //     })
  //     .catch(function (error: any) {
  //         remove('verify order')
  //         setIsvalidate(get('verify order'))
  //         navigate('/error&oq=error&gs_lcrp=')
  //     })
  //   }else{
  //     setIsLoading(false)
  //     setVerifiedPaymentId(paymentid)
  //     setIsvalidate(get('verify order'))
  //   }
  // })

  useEffect(() => {
    document.title = 'FIXING AND GAMING SERVICES';
  }, []);

  return (
    <>
      <div>
        {
          isLoading
          ?
          <main style={{width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Audio
                visible={true}
                height="96"
                width="96"
                color="grey"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
          </main>
          :
          <>
            <Nav siteData={siteData}  countCart={countCart} setCountCart={setCountCart}/>
            <DepositeRateContainer siteData={siteData} setSiteData={setSiteData} countCart={countCart} setCountCart={setCountCart}/>
          </>
        }
      </div>
      <Footer />
    </>
  )
}

export default DepositeRate