import { makeStyles } from "@mui/styles";
import { display } from "@mui/system";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme)=>{
    return {
        heroInner: {
            position: 'relative',
            zIndex: 5,
            paddingTop: '100px',
            maxWidth: '1170px',
            width: '100%',
            paddingRight: '15px',
            paddingLeft: '15px',
            marginRight: 'auto',
            marginLeft: 'auto',
        },
        heroInnerH1: {
            color: '#ffffffe6',
            fontSize: '2.4rem',
            fontWeight: 600,
            letterSpacing: '3px',
            padding: 0,
            margin: 0,
            lineHeight: 1,
            textTransform: 'uppercase',
            fontFamily: 'Raleway, sans-serif',

            [theme.breakpoints.down('md')]: {
                textAlign: 'center !important',
                fontSize: '2.2rem',
            },
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center !important',
                fontSize: '1.7rem',
            }
        },
        // Cart Styles
        innerMain: {
            maxWidth: '95%',
            margin: '0 auto'
        },
        inputBox: {
            width: '100%',
            padding: '0.5rem',
            // fontSize: '1.1rem',
        },
        cartItemsHolder: {
            '&:last-child': { // Target the actual last child using pseudo-selector
                // Your styles for the last child element here
                borderBottom: 'none !important',
                padding: '8rem !important'
            }
        },

        cartItemsHoldeRight: {
            textAlign: 'right',

            [theme.breakpoints.down('sm')]: {
                textAlign: 'left',
            }
        }
    }
})

export default useStyles