import React, { useEffect, useState } from 'react'
import CheckoutContainer from '../containers/CheckoutContainer';
import useStyles from '../styles/checkout';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Audio } from 'react-loader-spinner'

interface Props {

}
const Checkout: React.FC<Props>= ({  }) => {
    const classes = useStyles()
    const [initalPaymentData, setInitalPaymentData] = useState({})
    const [cartPaymentData, setcartPaymentData] = useState({})
    const [cryptoRates, setCryptoRates] = useState({})
    const [getCheckoutInfoError, setGetCheckoutInfoError] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
  const naviage = useNavigate()
    

  useEffect(() => {
    document.title = 'Check out';
  }, []);
  
  const { temppaymentid } = useParams();
  useEffect(()=>{
    setIsLoading(true)
    setGetCheckoutInfoError(false)
    const url = process.env.REACT_APP_GET_CHECKOUT_INFO || ''
    axios.post(url, { TempPaymentID: temppaymentid  })
    .then((response) => {

      setIsLoading(false)
      setGetCheckoutInfoError(false)

      setInitalPaymentData(response.data.PaymentObject)
      setcartPaymentData(response.data.CartSubmitInput)
      setCryptoRates(response.data.CryptoRates[0])
    })
    .catch((error) => {
      setIsLoading(false)
      setGetCheckoutInfoError(true)
      naviage('/error&oq=error&gs_lcrp=')
    } )
  }, [])


  return (
    <>
        {
          isLoading
          ?
          <main style={{width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Audio
                visible={true}
                height="96"
                width="96"
                color="grey"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
          </main>
          :
          <>
              <div>
                  <nav className={classes.nav}>
                      {/* <Link id='logo-holder' to="/" className={classes.logo}>
                        <h1>frdigitaltools</h1>
                      </Link>  */}

                      <h6 className={classes.logoText}>Checkout<span style={{color: '#e0452f', fontSize: '2.6rem'}}>.</span></h6>
                  </nav>
                  <CheckoutContainer classes={classes} cryptoRates={cryptoRates} initalPaymentData={initalPaymentData} cartPaymentData={cartPaymentData}/>
              </div>
          </>
        }
    </>
  )
}

export default Checkout

