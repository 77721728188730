import React, { useEffect, useState } from 'react'
import Nav from '../components/nav/Nav';
import SeachResultContainer from '../containers/SearchResult';
import { useParams } from 'react-router-dom';
import Footer from '../components/nav/Footer';


interface Props {
  siteData: any;
  setSiteData: any;
  countCart: any;
  setCountCart: any;
}
const SearchResult: React.FC<Props> = ({ siteData, setSiteData, countCart, setCountCart }) => {
  const { search } = useParams();
  useEffect(()=>{
    document.title = `Elite Community CU | Search Results for ${search}`
  }, [])


  return (
    <>
      <div>
        <Nav  siteData={siteData}  countCart={countCart} setCountCart={setCountCart}/>
        <SeachResultContainer searchTerm={search} siteData={siteData} setSiteData={setSiteData} countCart={countCart} setCountCart={setCountCart}/>
      </div>
      <Footer />
    </>
  )
}

export default SearchResult