import React, { useEffect, useRef, useState } from 'react'
import { RiCloseFill } from 'react-icons/ri'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material';


const useStyles = makeStyles((theme: Theme)=>{
    return {
        navIcon: {
            color: '#999 !important',
            fontSize: '1.7rem !important',
            fontWeight: '900 !important'
        },
        logo: {
            transition: 'height 0.4s ease 0s, opacity 0.3s ease 0s',
            margin:'0 auto'
        },
        logoHolder: {
          width: '100%',
          display: 'flex',
          justifyContent:'center',
          alignItems: 'center',

          transition: 'all 0.15s linear',
          marginTop: '30px',
          marginLeft: '10px',
          color: '#666',
          textDecoration: 'none !important',
          fontFamily: "Tiny5, sans-serif",
          fontSize: '1.2rem'
        },
        paragraph: {
          lineHeight: 1.6,
          color: '#555',
          fontSize: '0.978rem',
          fontStyle: 'normal',
          textAlign: 'left',
          fontWeight: '400',
          marginBottom: '30px'
      },
      footer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '1rem',
        [theme.breakpoints.down('md')]: {
          padding: '0rem',
        },
      },
      linkButton: {
        color: '#fff !important',
        backgroundColor: '#5d307f',
        borderColor: '#5d307f',
        marginLeft: '10px',
        display: 'inline-block',
        fontWeight: 400,
        textAlign: 'center',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        userSelect: 'none',
        border: '1px solid transparent',
        padding: '0.375rem 0.75rem',
        fontSize: '1rem',
        lineHeight: 1.5,
        borderRadius: '0.25rem',
        textDecoration: 'none',
        transition: 'all 0.15s ease-in-out',

        "&:hover": {
          color: '#000 !important',
          backgroundColor: '#e37927',
          borderColor: '#e37927',
        },

        [theme.breakpoints.down('sm')]: {
          marginLeft: '0',
          marginTop: '5px',
          display: 'block'
        },
      },
      linkButtonDisable: {
          color: '#fff !important',
          backgroundColor: '#333',
          borderColor: '#333',
          marginLeft: '10px',
          display: 'inline-block',
          fontWeight: 400,
          textAlign: 'center',
          whiteSpace: 'nowrap',
          verticalAlign: 'middle',
          userSelect: 'none',
          border: '1px solid transparent',
          padding: '0.375rem 0.75rem',
          fontSize: '1rem',
          lineHeight: 1.5,
          borderRadius: '0.25rem',
          textDecoration: 'none',
          transition: 'all 0.15s ease-in-out',
          cursor: 'not-allowed'
      },
      asideInner: {
        padding: '2rem',

        [theme.breakpoints.down('md')]: {
          padding: '1rem',
        },
        [theme.breakpoints.down('sm')]: {
          padding: '0rem',
        },
      }
    }
})


interface Props{
    needed: boolean
    setNeeded: React.Dispatch<React.SetStateAction<boolean>>
    game: string
    setGame: React.Dispatch<React.SetStateAction<string>>
    toolId: number
    setToolId: React.Dispatch<React.SetStateAction<number>>
    disc: any
    setDisc: any
    addToCart: any
    incart: any
    setIncart: any
}
const Popup: React.FC<Props> = ({needed, setNeeded, game, setGame, toolId, setToolId, disc, setDisc,addToCart, incart, setIncart}) => {
  const classes = useStyles()


  const closePopup =()=>{
    setNeeded(false)
    setGame('')
    setToolId(0)
    setDisc([])
  }

  const [d, setd] = useState(new Date())

  const handleClick =()=>{
    addToCart(game, toolId)
    setIncart(true)
  }

  return (
    <div data-aos="zoom-in" data-aos-delay="0" data-aos-duration="100" className={`${needed ? 'sidebar-wrapper show' : 'sidebar-wrapper'}`}>
      <aside className='sidebar'>
        <div className={classes.asideInner}>
          <button className='close-btn' onClick={()=> closePopup()}>
            <RiCloseFill  className={classes.navIcon} />
          </button>
          
          <section style={{padding: '0 20px'}}>
            
              <div className={classes.logoHolder}>
                <h1>frdigitaltools</h1>
              </div>
            <br />

                <div>
                    <ul className={classes.paragraph}>
                      {
                        disc.map((item: string, index: number) => {
                          return(
                            <li key={item}>{item}</li>
                          )
                        })
                      }
                      {/* <li>By continuing to this link, you will be leaving the Elite Community Credit Union. </li>
                      <li>Links to other websites found on the Elite Community Credit Union site are intended to provide assistance in locating information. Elite Community Credit Union does not endorse, guarantee, or attest to the accuracy of any information provided by these other sites. </li>
                      <li>The credit union is not responsible for the content of these other sites and these sites are in no way operated by Elite Community Credit Union. </li><li>The privacy and security policies of these linked sites may vary from those of the credit union; therefore, it is advised that you review the privacy information of each site visited.</li> */}
                    </ul>

                    <div className={classes.footer}>
                      <p style={{marginBottom: '10px'}}>
                        <a style={{cursor: 'pointer', color: '#000', lineHeight: '1', fontSize: '0.897rem'}} role="button" onClick={closePopup}>Cancel</a> 

                        {
                          !incart
                          ?
                          <a className={classes.linkButton}  onClick={()=> handleClick()}>add to cart</a>
                          :
                          <a className={classes.linkButtonDisable}>in cart</a>
                        }
                      </p>
                    </div>

                </div>

               

          </section>
        </div>

        
      </aside>
    </div>
  )
}

export default Popup
