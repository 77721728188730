import React, { useEffect } from 'react'
import Nav from '../components/nav/Nav';
import CartContainer from '../containers/CartContainer';
import Footer from '../components/nav/Footer';


interface Props {
  siteData: any;
  setSiteData: any;
  countCart: any;
  setCountCart: any;
}
const Cart: React.FC<Props>= ({ siteData, setSiteData, countCart, setCountCart }) => {

  useEffect(() => {
    document.title = 'Cart';
  }, []);

  return (
    <>
      <div>
        <Nav siteData={siteData}  countCart={countCart} setCountCart={setCountCart}/>
        <CartContainer siteData={siteData} setSiteData={setSiteData} countCart={countCart} setCountCart={setCountCart} />
      </div>
    
      <Footer />
    </>
  )
}

export default Cart