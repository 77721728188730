import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material';
import { Link } from 'react-router-dom';



const useStyles = makeStyles((theme: Theme)=>{
    return {
        footer: {
            width: '100%',
            padding: '3rem 1rem',
            backgroundColor: '#333',
            textAlign: 'center',
            color: '#fff',
            marginTop: '1.5rem'
        },
        link: {
            margin: '0 0.3rem',
            color: '#fff',
            fontSize: '0.9rem'
        }
    }
})

interface Props {

}
const Footer: React.FC<Props> = ( ) =>{
    const classes = useStyles()
    return (
        <footer className={classes.footer} id='nav-footer'>
            <div style={{marginBottom: '1.4rem'}}>
                <Link to='/' className={classes.link}>Home</Link>
                <Link to='/cart' className={classes.link}>Cart</Link>
                <Link to='/search-results/search?' className={classes.link}>Search</Link>
                <Link to='mailto:digitalstepupworld@gmail.com' className={classes.link}>Support</Link>
            </div>
            <p style={{fontSize: '0.9rem', marginBottom: '0.4rem'}}>Instagram: <a href="https://www.instagram.com/frdigitaltool5?igsh=ajAxMm01Nzh3bWhh" style={{color: 'hsl(205, 86%, 81%)'}}>frdigitaltool5</a></p>

            <p>© 2020 - 2024, frdigitaltools. All rights reserved.</p>
        </footer>
    )
}

export default Footer