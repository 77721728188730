import React, { useEffect, useState } from 'react'
import useStyles from '../styles/cart';
import { styled } from '@mui/system'
import { Button, Grid } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { MdDeleteOutline, MdOutlineDeleteOutline } from 'react-icons/md';
import axios from 'axios';
import { Oval } from 'react-loader-spinner';
import { get, set, remove, clear } from 'local-storage';

interface Props {
    siteData: any;
    setSiteData: any;
    countCart: any;
    setCountCart: any;
}
const CartContainer: React.FC<Props> = ({ siteData, setSiteData, countCart, setCountCart }) => {
    const classes = useStyles()
    const navigate = useNavigate()

    const [cartList, setCartList] = useState<any>([])
    const [totalAmount, setTotalAmount] = useState(0)
    const [isPromoCodeActive, setIsPromocodeActive] = useState(false)
    const [promocode, setPromocode] = useState('')
    const [promocodeMessage, setPromocodeMessage] = useState('')
    const [discountAmount, setDiscountAmount] = useState('')
    const [paymentidInput, setPaymentIdInput] = useState('')

    function subtractPercent(price: number, percent: number) {
        const discount = price * percent;
      
        return (price - discount).toFixed(2);
    }

    const handlePromocodeChange =(e: React.ChangeEvent<HTMLInputElement>)=>{
        setPromocode(e.currentTarget.value)
        if(e.currentTarget.value === 'd4yX2w0j4T1K' || e.currentTarget.value === 'oNxBq78ZfVmt' || e.currentTarget.value === '9uLPJ3z21hEk' || e.currentTarget.value === 'WCYs6rXkTmQA'){
            setIsPromocodeActive(true)
        }else{
            setIsPromocodeActive(false)
        }
    }

    useEffect(()=>{
        if(isPromoCodeActive){
            if(promocode === 'd4yX2w0j4T1K'){
                setPromocodeMessage('5% of active')
                setDiscountAmount(subtractPercent(totalAmount, 0.05))
            }else if(promocode === 'oNxBq78ZfVmt'){
                setPromocodeMessage('10% of active')
                setDiscountAmount(subtractPercent(totalAmount, 0.10))
            }else if(promocode === '9uLPJ3z21hEk'){
                setPromocodeMessage('20% of active')
                setDiscountAmount(subtractPercent(totalAmount, 0.20))
            }else if(promocode === 'WCYs6rXkTmQA'){
                setPromocodeMessage('50% of active')
                setDiscountAmount(subtractPercent(totalAmount, 0.50))
            }
        }
    },[isPromoCodeActive, countCart, totalAmount])

    const getTotalPrice = () => {
        return cartList.reduce((total: any, item: any) => total + item.price, 0);
    };
    useEffect(()=> {
        setCartList([])
        if(siteData){
            siteData.fortnite.map((item: any) => {
                if(item.incart == true){
                    setCartList((prevCountCart: any) => [...prevCountCart, item])
                }
            })
            siteData.pokemon.map((item: any) => {
                if(item.incart == true){
                    setCartList((prevCountCart: any) => [...prevCountCart, item])
                }
            })
            siteData.cod.map((item: any) => {
                if(item.incart == true){
                    setCartList((prevCountCart: any) => [...prevCountCart, item])
                }
            })
            siteData.apex.map((item: any) => {
                if(item.incart == true){
                    setCartList((prevCountCart: any) => [...prevCountCart, item])
                }
            })
            siteData.rainbow6.map((item: any) => {
                if(item.incart == true){
                    setCartList((prevCountCart: any) => [...prevCountCart, item])
                }
            })
            siteData.gta.map((item: any) => {
                if(item.incart == true){
                    setCartList((prevCountCart: any) => [...prevCountCart, item])
                }
            })
            siteData.mobile.map((item: any) => {
                if(item.incart == true){
                    setCartList((prevCountCart: any) => [...prevCountCart, item])
                }
            })
        }
    }, [siteData])

    useEffect(()=>{
        setTotalAmount(getTotalPrice())
    }, [cartList])

    useEffect(() => {
        const sectionEl = document.getElementById("deposite-rate-hero")
        const handleScroll = () => {
            const position = 217.168 + window.scrollY * 0.65
            if(sectionEl){
                if(position >= 500){
                    sectionEl.style.backgroundPosition = `0px -500px`
                }else{
                    sectionEl.style.backgroundPosition = `0px -${position}px`
                }
            }
            
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => window.removeEventListener('scroll', handleScroll);
      }, []);
    const Hero = styled('section')(({ theme }) => ({
        position: 'relative',
        backgroundImage: 'url(imgs/cart.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: '0px -217.168px',
        padding: '90px 0px',
        top: '-100px',
        marginBottom: '-100px',
        textShadow: 'none !important',
        borderBottom: 'none',
        backgroundColor: 'transparent',
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        transition: 'all 0.17s linear !important',
        textAlign: 'center',

        [theme.breakpoints.down('md')]: {
            padding: '90px 0px',
        },
        [theme.breakpoints.down('md')]: {
            padding: '60px 0px',
        },
    }));
    const MainContent = styled('section')(({ theme }) => ({
        position: 'relative',
        overflow: 'hidden',
        background: '#fff',
        margin: '0',
    }));


    function generateRandomString(length: number) {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";
      
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
      
        return result;
      }

    const [loading, setLoading] = useState(false)
    const [failed, setFailed] = useState(false)
    const onCheckoutSubmit =(e: React.FormEvent<HTMLFormElement>)=>{
        e.preventDefault()
    
        if(countCart <= 0){
            alert('your cart is empty')
        }else{
               const tempPaymentID = generateRandomString(12)
               setLoading(true)
               setFailed(false)
               const url = process.env.REACT_APP_CART_SUBMIT || ''
               axios.post(url, {
                temppaymentid: tempPaymentID,
                paymentidinput: paymentidInput,
                promocode: promocode,
                cartlist: cartList,
                totalamount: totalAmount,
                discountamount: discountAmount,
               })
               .then(function (response: any) {
                setLoading(false)
                if(response.data.isvalidated === true){
                    setFailed(false)
                    remove('verify order')
                    navigate(`/checkout/${tempPaymentID}/${paymentidInput}`)

                }else{
                    setFailed(true)
                }
              })
              .catch(function (error: any) {
                setFailed(true)
                setLoading(false)
              })
        }
    }

    const removeFromCart =( game: string, id: any )=> {
        switch (game) {
            case 'fortnite':
                setSiteData({...siteData, fortnite: siteData.fortnite.map( (item: any) => item.id === id ? { ...item, incart: false } : {...item} )})
                setCountCart(countCart - 1)
                break;
            case 'pokemon':
                setSiteData({...siteData, pokemon: siteData.pokemon.map( (item: any) => item.id === id ? { ...item, incart: false } : {...item} )})
                setCountCart(countCart - 1)
                break;
             case 'cod':
                setSiteData({...siteData, cod: siteData.cod.map( (item: any) => item.id === id ? { ...item, incart: false } : {...item} )})
                setCountCart(countCart - 1)
                break;
            case 'apex':
                setSiteData({...siteData, apex: siteData.apex.map( (item: any) => item.id === id ? { ...item, incart: false } : {...item} )})
                setCountCart(countCart - 1)
                break;
            case 'rainbow6':
                setSiteData({...siteData, rainbow6: siteData.rainbow6.map( (item: any) => item.id === id ? { ...item, incart: false } : {...item} )})
                setCountCart(countCart - 1)
                break;
            case 'gta':
            setSiteData({...siteData, gta: siteData.gta.map( (item: any) => item.id === id ? { ...item, incart: false } : {...item} )})
            setCountCart(countCart - 1)
            break;

            case 'mobile':
            setSiteData({...siteData, mobile: siteData.mobile.map( (item: any) => item.id === id ? { ...item, incart: false } : {...item} )})
            setCountCart(countCart - 1)
            break;
                                   
        
            default:
                break;
        }
    }


  return (
        <main>
            <Hero id='deposite-rate-hero'>
                <div className={classes.heroInner}>
                    <h1 className={classes.heroInnerH1}>Your Cart</h1>
                </div>
            </Hero>

            <main className={classes.innerMain} style={{paddingTop: "3rem"}}>

                <Grid container style={{justifyContent: 'space-between'}}>
                    <Grid item xs={12} md={9} style={{height: "fit-content", borderRadius: '5px', marginTop: '16px'}}>
                        <section style={{backgroundColor: "#ffffff"}}>

                            <header style={{padding: '8px 0', borderBottom: "1px solid #f1f1f2"}}>
                                <h2  style={{fontSize: '1.25rem', display: 'block', padding: '4px 16px', fontWeight: '500'}}>Cart  { countCart <= 0 ? null : `(${countCart})`}</h2>
                            </header>

                            
                            <div className='cartItemsHolder'>
                                {
                                    cartList.map((item: any) => {
                                        return (
                                            <article style={{margin: "0 16px", padding: "16px 0", borderBottom: "1px solid #bbb"}}>
                                                <Grid container>
                                                    <Grid item xs={12} sm={10} style={{marginBottom: '0.5rem'}}>
                                                        <h3 style={{fontWeight: '500', marginBottom: '5px'}}>{item.name}</h3>
                                                        <span style={{display: 'block', lineHeight: "1.6", fontSize: '0.8rem'}}>{item.game === 'fortnite' ? 'fortnite' : item.game === 'apex' ? 'apex' : item.game === 'pokemon' ? 'pokemon go' : item.game === 'cod' ? 'call of duty' : item.game === 'rainbow6' ? 'rainbow six siege' : item.game === 'gta' ? 'grand theft auto' : item.mobile === 'mobile' ? 'mobile' : null}</span>
                                                    </Grid>
                                                    <Grid item xs={12} sm={1.8} className={classes.cartItemsHoldeRight} style={{marginBottom: '0.5rem'}}>
                                                        <div style={{fontWeight: '600', fontSize: '1.3rem'}}>$ {item.price}</div>
                                                    </Grid>
                                                </Grid>

                                                <span style={{display: 'block', lineHeight: "1.6", fontSize: '0.8rem', color:'green'}}>Applicable</span>
                                                {/* <a href='https://www.stargamingstore.shop/' style={{display: 'inline', lineHeight: "1.6", fontSize: '0.9rem'}}>Star Gaming Store</a> */}

                                                <footer style={{marginTop: '1rem'}}>
                                                    <div onClick={()=> removeFromCart(item.game, item.id)} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'red', textTransform: 'uppercase', cursor: 'pointer'}}><MdOutlineDeleteOutline /> Remove</div>
                                                </footer>
                                            </article>
                                        )
                                    })
                                }

                            </div>

                        </section>
                        
                        {
                            cartList.length <= 0
                            ?
                            <div style={{textAlign: 'center', padding: '1.5rem 0', color: '#777'}}>Cart is empty</div>
                            :
                            null
                        }
                    </Grid>
                    

                    <Grid item xs={12} md={2.8} style={{backgroundColor: "#ffffff", height: "fit-content", borderRadius: '5px', marginTop: '16px'}}>
                        <header style={{padding: '8px 0', borderBottom: "1px solid #f1f1f2"}}>
                            <h2  style={{fontSize: '1.25rem', display: 'block', padding: '4px 16px', fontWeight: '500'}}>Cart Summary</h2>
                        </header>

                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: "0 16px", padding: "16px 0", borderBottom: "1px solid #bbb"}}>
                            <p>Subtotal:</p>
                            <p style={{fontWeight: 'bold', fontSize: '1rem'}}>$ {totalAmount.toFixed(2)}</p>
                        </div>

                        <form onSubmit={(e)=> onCheckoutSubmit(e)}>
                            <div style={{margin: '1rem 0', marginBottom: '0', padding: '4px 16px'}}>
                                <input type="text" required placeholder='RRR *' value={paymentidInput} className={classes.inputBox} onChange={(e)=> setPaymentIdInput(e.currentTarget.value)} />
                            </div>
                            <p style={{fontSize: '0.75rem', padding: '4px 16px'}}>
                                To generate your RRR number, simply submit your <span style={{fontWeight: 'bold'}}>full name</span>, <span style={{fontWeight: 'bold'}}>country</span>, and preferred <span style={{fontWeight: 'bold'}}>email address</span> to our support mail address - <span style={{fontWeight: 'bold'}}>digitalstepupworld@gmail.com</span>. Your RRR number will be generated and sent to your inbox within minutes
                            </p>
                            <div style={{margin: '1rem 0', padding: '4px 16px'}}>
                                <input type="text" value={promocode} placeholder='coupon code?' className={classes.inputBox} onChange={(e)=> handlePromocodeChange(e)}/>
                                <span style={{fontSize: '0.7rem', color: '#ff5722'}}>{ promocode && !isPromoCodeActive ? 'invalid promotion code' : isPromoCodeActive ? <span style={{fontSize: '0.7rem', color: 'green'}}>{promocodeMessage}</span> : null }</span>
                            </div>

                            <div style={{margin: '1rem 0', padding: '4px 16px'}}>
                                <Button disabled={loading} type='submit' variant='contained'>
                                    {
                                        loading
                                        ?
                                            <Oval
                                                visible={true}
                                                height="20"
                                                width="20"
                                                color="#fff"
                                                ariaLabel="oval-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                            />
                                        :
                                        <>
                                            Checkout (${isPromoCodeActive && discountAmount ? discountAmount : totalAmount.toFixed(2)})
                                        </>
                                        
                                    }
                                </Button><br />
                                    {failed ? <span style={{color: 'red', fontSize: '0.773rem'}}>Oops! Something went wrong with checkout. Please try again</span> : null}
                            </div>
                        </form>
                    </Grid>
                </Grid>

            </main>

        </main>
  )
}

export default CartContainer
